<template>
	<section class="text-image" :style="{backgroundColor: bgColor}">
		<div class="section-content-wrapper" :class="{ wide: !wideImage }">
			<div class="text-image-wrapper" :class="imagePos">
				<article class="text-container" :style="{ color: textColor }">
					<h2>{{ heading }}</h2>
					<p v-html="paragraph"></p>
					<a
						:v-if="button"
						:href="buttonItem ? buttonItem.url : null"
						:class="{ hide: !button }"
						:style="{ color: textColor, border: '2px solid' + textColor }"
					>
						{{ buttonItem ? buttonItem.title : null }}
					</a>
				</article>
				<article class="img-container" :class="{ wide: wideImage }">
					<img :src="baseApiUrl + imageUrl" alt="" />
				</article>
			</div>
		</div>
	</section>
</template>
<script>
import { baseUrl } from '@/assets/apiLink.js'
export default {
	name: 'TextImage',
	props: {
		imageUrl: {
			type: String,
			required: true,
		},
		imagePos: {
			type: String,
			required: true,
		},
		heading: {
			type: String,
			required: true,
		},
		paragraph: {
			type: String,
			required: true,
		},
		button: {
			type: Boolean,
			required: true,
		},
		buttonItem: {
			type: Object,
			required: false,
			default: function () {
				return {
					url: '',
					title: '',
				}
			},
		},
		wideImage: {
			type: Boolean,
			required: false,
		},
		bgColor: {
			type: String,
			required: true,
		},
		textColor: {
			type: String,
			required: true,
		},
	},
	data: function () {
		return {
			baseApiUrl: baseUrl,
		}
	},
}
</script>
<style scoped lang="scss">
.text-image {
	background-color: $color-grey-dark;
	.section-content-wrapper {
		.text-image-wrapper {
			display: flex;
			flex-direction: row;
			width: 100%;

			&.left {
				flex-direction: row-reverse;
			}

			.text-container {
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin-right: 5%;
				width: 50%;
				a {
					@include paragraph-small;

					font-family: $secondary-font-sbold;
					line-height: normal;
					text-transform: uppercase;
					text-decoration: none;
					border-radius: 24px;
					padding: 16px 56px;
					margin-top: 32px;
					width: fit-content;

					&.hide {
						display: none;
					}
				}

				p {
					width: 100%;
					margin-top: 0;
					line-height: 26px;
				}

				h2 {
					text-transform: uppercase;
					margin-bottom: 30px;
					margin-top: 0px;
					max-width: 460px;

					@include max-width(medium) {
						margin-bottom: 10px;
					}
				}
			}

			.img-container {
				@include aspect-ratio(0.9, 1);
				width: 50%;
				max-height: 500px;

				&.wide {
					@include aspect-ratio(1.14, 1);
				}
			}
			&.left {
				.text-container {
					margin-left: 5%;
					margin-right: 0;
				}
			}
			@include max-width(medium) {
				&,
				&.left {
					flex-direction: column-reverse;
					width: 100%;
					.text-container {
						margin-left: 0;
						margin-right: 0;
					}
				}
				.text-container {
					width: 100%;
				}
				.img-container {
					width: 100%;
					margin-bottom: 15px;
				}
			}
		}
	}
}
</style>
